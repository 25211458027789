<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>产品管理</p>
			<i>/</i>
			<p>标准产品列表</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px">
						<el-form-item label="产品名称:" @submit.native.prevent>
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable placeholder="请输入产品名称"></el-input>
						</el-form-item>
						<el-form-item label="产品编码:">
							<el-input class="input_medi" v-model="product_sn" @keyup.enter.native="searchFn" clearable placeholder="请输入产品名称"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>产品列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增产品</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="product_sn" label="产品编号" align="left"></el-table-column>
				<el-table-column prop="cat_name" label="产品分类" align="center"></el-table-column>
				<el-table-column prop="title" label="产品名称" align="center"></el-table-column>
				<el-table-column label="产品级别" align="center">
					<template slot-scope="scope">
						<div>
							<p v-if="scope.row.rank == '1'">平台级</p>
							<p v-if="scope.row.rank == '2'">用户级</p>
							<p v-if="scope.row.rank == '3'">设备级</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.is_sale == '1'">
							<p class="is_sale_1">已上架</p>
						</div>
						<div v-else>
							<p class="is_sale_0">已下架</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="sales_volume" label="销量" align="center"></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">编辑</span>
							<span v-if="scope.row.is_sale == '1'" @click="goodsFrame(scope.row, '0')">下架</span>
							<span v-else  @click="goodsFrame(scope.row, '1')">上架</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>

	</div>


</template>

<script>
	export default {
		name: 'standardGoods',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				product_sn: '', // 编码搜索
			}
		},
		components: {},
		created() {
			// 获取商品列表
			this.getGoodsList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.product_sn = '';
				this.getGoodsList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getGoodsList();
			},

			// 获取商品列表
			getGoodsList() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}products/list`,
					data: {
						page: this.currentPage1,
						title: this.keyword,
						product_sn: this.product_sn,
					}
				}).then(res => {
					if (res.code == 0) {
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getGoodsList();
			},
			
			// 产品上下架
			goodsFrame(row, sale){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}products/issale`,
					data: {
						product_id: row.id,
						is_sale: sale,
					}
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						if(sale == '0'){
							row.is_sale = '0';
						}
						else{
							row.is_sale = '1';
						}
					}
					else{
						this.$message.error(`${res.msg}`);
					}
				})
			},

			// 修改
			modifyFn(row) {
				this.$router.push({
					path: '/standardGoodsEdit',
					query:{
						id: row.id,
					}
				})
			},

			// 新增
			addFn() {
				this.$router.push('/standardGoodsEdit');
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
	
	.is_sale_1{
		font-size: 14px;
		color: #207CFF;
	}
	.is_sale_0{
		font-size: 14px;
		color: rgba(0, 0, 0, .65);
	}
	
	
</style>
